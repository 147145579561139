<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      item-key="city_id"
      hide-default-footer
      class="table-rounded"
      :loading="loading"
    >
      <!-- status -->
      <template #[`item.clicksPercent`]="{item}">
        <v-row>
          <v-col
            cols="8"
            sm="8"
            md="8"
            lg="8"
          >
            <v-progress-linear
              class="mt-2 mr-2"
              :value="item.clicksPercent"
              height="10"
            >
            </v-progress-linear>
          </v-col>
          <v-col
            cols="4"
            sm="4"
            md="4"
            lg="4"
          >
            <span>{{ item.clicksPercent.toFixed(2) + '%' }}</span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  components: {},
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    locations: {
      type: Array,
      default() {
        return []
      },
    },
    campaingTypes: {
      type: Array,
      default() {
        return []
      },
    },
    statuses: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      search: '',
      calories: '',
      data: [],
      loading: true,
      headers: [
        { text: this.$t('Country'), value: this.$i18n.locale === 'ru' ? 'countryRu' : 'countryEn' },
        { text: this.$t('References'), value: 'clicks' },
        { text: `${this.$t('References')},%`, value: 'clicksPercent' },
      ],
    }
  },
  watch: {
    dateFrom() {
      // watch it
      this.getData()
    },
    dateTo() {
      // watch it
      this.getData()
    },
    locations() {
      this.getData()
    },
    campaingTypes() {
      this.getData()
    },
    statuses() {
      this.getData()
    },
  },
  mounted() {
    this.getData()

    // this.$root.$on('update_country', this.getData())
  },
  methods: {
    getData() {
      this.loading = true
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          statuses: this.statuses,
          locations: this.locations,
          campaingTypes: this.campaingTypes,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/widgets/geo/country', params)
      this.$http.get(`${this.$apiBaseURL}/widgets/country`, params).then(response => {
        this.data = response.data === null ? [] : response.data
        this.loading = false
      })
    },
  },
}
</script>
