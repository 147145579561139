<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="article"
        ></v-skeleton-loader>
        <v-card
          v-show="loading === false"
          elevation="8"
        >
          <v-card-title>{{ $t('Campaign.Locations.Desktop') }}</v-card-title>
          <v-card-text>
            <v-list class="py-0">
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('Shows') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ data.desktop.views }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('References') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ data.desktop.clicks }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('CTR') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ parseFloat(data.desktop.ctr).toFixed(1) }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="article"
        ></v-skeleton-loader>
        <v-card
          v-show="loading === false"
          elevation="8"
        >
          <v-card-title>{{ $t('Campaign.Locations.Mobile') }}</v-card-title>
          <v-card-text>
            <v-list class="py-0">
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('Shows') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ data.mobile.views }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('References') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ data.mobile.clicks }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
              <v-list-item
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm font-weight-semibold">
                      <span class="primary--text">{{ $t('CTR') }}</span>
                    </v-list-item-title>
                  </div>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-center">
                    <h5 class="text-base font-weight-semibold me-1">
                      {{ parseFloat(data.mobile.ctr).toFixed(1) }}
                    </h5>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  components: {},
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    locations: {
      type: Array,
      default() {
        return []
      },
    },
    campaingTypes: {
      type: Array,
      default() {
        return []
      },
    },
    statuses: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      search: '',
      calories: '',
      data: {
        desktop: {
          views: 0,
          clicks: 0,
          ctr: 0.0,
        },
        mobile: {
          views: 0,
          clicks: 0,
          ctr: 0.0,
        },
      },
      loading: true,
    }
  },
  watch: {
    dateFrom() {
      // watch it
      this.getData()
    },
    dateTo() {
      // watch it
      this.getData()
    },
    locations() {
      this.getData()
    },
    campaingTypes() {
      this.getData()
    },
    statuses() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          statuses: this.statuses,
          locations: this.locations,
          campaignTypes: this.campaingTypes,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/widgets/total', params)
      this.$http.get(`${this.$apiBaseURL}/widgets/total`, params).then(response => {
        let tempData = []
        this.data = {
          desktop: {
            views: 0,
            clicks: 0,
            ctr: 0.0,
          },
          mobile: {
            views: 0,
            clicks: 0,
            ctr: 0.0,
          },
        }
        tempData = response.data
        if (tempData !== null) {
          const desktop = tempData.find(c => c.locationId === 'desktop')
          const mobile = tempData.find(c => c.locationId === 'mobile')
          if (desktop) {
            this.data.desktop = { views: desktop.views, clicks: desktop.clicks, ctr: desktop.ctr }
          }
          if (mobile) {
            this.data.mobile = { views: mobile.views, clicks: mobile.clicks, ctr: mobile.ctr }
          }
        }
        this.loading = false
      })
    },
  },
}
</script>
