import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"country_iso_code","hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.clicksPercent",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"8","sm":"8","md":"8","lg":"8"}},[_c(VProgressLinear,{staticClass:"mt-2 mr-2",attrs:{"value":item.clicksPercent,"height":"10"}})],1),_c(VCol,{attrs:{"cols":"4","sm":"4","md":"4","lg":"4"}},[_c('span',[_vm._v(_vm._s(item.clicksPercent.toFixed(2) + '%'))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }