<template>
  <div>
    <v-tabs vertical>
      <v-tab>
        {{ $t('Country') }}
      </v-tab>
      <v-tab>
        {{ $t('City') }}
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <country-component
              :date-from="dateFrom"
              :date-to="dateTo"
              :locations="locations"
              :campaing-types="campaingTypes"
              :statuses="statuses"
            ></country-component>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <city-component
              :date-from="dateFrom"
              :date-to="dateTo"
              :locations="locations"
              :campaing-types="campaingTypes"
              :statuses="statuses"
            ></city-component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CountryComponent from './GeoWidgetComponents/CountryComponent.vue'
import CityComponent from './GeoWidgetComponents/CityComponent.vue'

export default {
  components: {
    CountryComponent,
    CityComponent,
  },
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    locations: {
      type: Array,
      default() {
        return []
      },
    },
    campaingTypes: {
      type: Array,
      default() {
        return []
      },
    },
    statuses: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      loading: true,
    }
  },
}
</script>
